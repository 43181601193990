import { DeviceDataCollectionDto } from '@shared/models/wallets/device-data-initialization';

export class DeviceDataCollectionHandler {
  private iframeId: string = 'device-data-collection-handler';
  private maxWaitingTimeSeconds: number = 15;
  private result: DeviceDataCollectionDto | null = null;
  private messageHandler: ((event: MessageEvent) => void) | null = null;

  static create(): DeviceDataCollectionHandler {
    return new DeviceDataCollectionHandler();
  }

  async process(markup: string): Promise<DeviceDataCollectionDto | null> {
    this.createIFrame(markup);
    await this.waitIframeResponse();
    this.cleanup();
    return this.result;
  }

  private createIFrame(markup: string) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', this.iframeId);
    iframe.setAttribute('src', `data:text/html,${markup}`);
    iframe.height = '1px';
    iframe.width = '1px';
    iframe.style.display = 'none';
    const root = document.getElementById('root');
    if (root) {
      root.appendChild(iframe);
    }
    this.messageHandler = (event: MessageEvent) => {
      if (typeof event.data === 'string') {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData.MessageType === 'profile.completed') {
            this.result = {
              sessionId: parsedData.SessionId,
              status: parsedData.Status,
            };
          }
        } catch {}
      }
    };
    window.addEventListener('message', this.messageHandler);
  }

  private waitIframeResponse() {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        clearInterval(interval);
        return reject();
      }, this.maxWaitingTimeSeconds * 1000);
      const interval = setInterval(() => {
        if (this.result) {
          clearTimeout(timeout);
          resolve(true);
        }
      }, 100);
    });
  }

  private cleanup() {
    const iframe = document.getElementById(this.iframeId);
    if (iframe) {
      iframe.remove();
    }

    if (this.messageHandler) {
      window.removeEventListener('message', this.messageHandler);
      this.messageHandler = null;
    }
  }
}
